import { Spinner, Box, Text, Flex } from "@chakra-ui/react"
import env from '../env';
const ProjectMintSuccess=({ dataEth, setDataEth}) => {

    const mintSuccess = () => {
        // if (true) {
        if (dataEth?.tx?.waitingForTx && !dataEth?.tx?.mintedSuccess) {
            return (
                <div>
                    <Flex className="aboveAll">
                        <Box width="520px" color="black">
                            <Text fontSize="24px" fontWeight="600" marginTop="15">Thanks for minting!</Text>
                            <Text fontSize="14px" margin="10">Waiting for transaction receipt</Text>
                            <Text fontSize="8px" margin="5">
                                Transaction Hash: 
                                <a href={env.url_etherscan+(dataEth?.tx?.txID)} target="_blank" rel="noreferrer noopener">
                                    {dataEth?.tx?.txID}
                                </a>
                            </Text>
                        </Box>
                        <Spinner 
                            thickness='7px'
                            speed='0.85s'
                            emptyColor='gray.200'
                            color='blue.700'
                            size='xl' />
                    </Flex>
                </div>
            )
        }
    }




    return(
        <>
            {mintSuccess()}
        </>
    )
}

export default ProjectMintSuccess;