import React from 'react';
import { Text, IconButton, Box, } from "@chakra-ui/react"
import { LockIcon } from '@chakra-ui/icons'


const MetamaskShowETH = ({ dataEth, setDataEth, dataErr, setDataErr }) => {

    const logout = () => {
        setDataEth();
    }

    return (
        <div style={{ display: 'inline', verticalAlign: 'middle' }}>
            <Box display="flex" margin="0 15px" alignItems="center">
                    {Number(dataEth?.accountEth?.balance).toFixed(3)} Ξ
                    <div style={{ paddingLeft: '5px', display: 'inline', verticalAlign: 'middle' }}>
                        <IconButton
                            colorScheme='gray'
                            aria-label='Logout BEYC'
                            size='sm'
                            isRound='true'
                            icon={<LockIcon />}
                            onClick={logout}
                        />
                    </div>
            </Box>
                <Text
                    fontSize="11px"
                    marginTop="5px"
                    >
                    {dataEth?.accountEth?.account.substring(0,6)}...{dataEth?.accountEth?.account.substring(dataEth?.accountEth?.account.length-4)}
                </Text>
        </div>
    )
}

export default MetamaskShowETH;