let env = {
    mintContract: "Pending....",
    
    url_etherscan: "https://etherscan.io/tx/",
    url_etherscan_address: "https://etherscan.io/address/",
    openSeaCollection: "https://opensea.io/collection/DosLarvasSynth/",
    raribleCollection: "https://rarible.com/DosLarvasSynth/",
    looksRareCollection: "",
    twitter_url: "https://twitter.com/doslarvas/",
    discord: "https://discord.gg/eSn9Vyb7bb",
    radio: "https://zeno.fm/radio/larva-city-radio/",
    story: false,
    
    networkRequired: "mainnet",
    //networkRequired: "rinkeby",


    networks: {
        mainnet: {
            chainId: `0x${Number(1).toString(16)}`,
            chainName:"Ethereum Mainnet",
            nativeCurrency:{
                name:"Ether",
                symbol:"ETH",
                decimals:18 
            },
            rpcUrls:[
                "https://main-light.eth.linkpool.io",
                "https://main-rpc.linkpool.io",
                "https://eth-rpc.gateway.pokt.network",
                "https://cloudflare-eth.com",
                "https://rpc.flashbots.net",
            ],
            blockExplorerUrls:[
                "https://etherscan.io"
            ]},
        rinkeby: {
          chainId: `0x${Number(4).toString(16)}`,
          chainName:"Rinkeby ETH Test Network",
            nativeCurrency:{
                name:"Rink",
                symbol:"RIN",
                decimals:18 
            },
            rpcUrls:[
                "https://rinkeby.infura.io/v3/{API_KEY",
            ],
            blockExplorerUrls:[
                "https://rinkeby.etherscan.io"
            ]},
        polygon: {
          chainId: `0x${Number(137).toString(16)}`,
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
          },
          rpcUrls: ["https://polygon-rpc.com/"],
          blockExplorerUrls: ["https://polygonscan.com/"]
        },
        bsc: {
          chainId: `0x${Number(56).toString(16)}`,
          chainName: "Binance Smart Chain Mainnet",
          nativeCurrency: {
            name: "Binance Chain Native Token",
            symbol: "BNB",
            decimals: 18
          },
          rpcUrls: [
            "https://bsc-dataseed1.binance.org",
            "https://bsc-dataseed2.binance.org",
            "https://bsc-dataseed3.binance.org",
            "https://bsc-dataseed4.binance.org",
            "https://bsc-dataseed1.defibit.io",
            "https://bsc-dataseed2.defibit.io",
            "https://bsc-dataseed3.defibit.io",
            "https://bsc-dataseed4.defibit.io",
            "https://bsc-dataseed1.ninicoin.io",
            "https://bsc-dataseed2.ninicoin.io",
            "https://bsc-dataseed3.ninicoin.io",
            "https://bsc-dataseed4.ninicoin.io",
            "wss://bsc-ws-node.nariox.org"
          ],
          blockExplorerUrls: ["https://bscscan.com"]
        }
      }
}

export default env;