import React, { Component } from 'react';
import { Text } from "@chakra-ui/react"

class Story extends Component {
    render() {
        return(
            <>
                    <Text marginLeft="50px" align="left" fontSize="28" fontWeight="600">
                        Story
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        Larvas were born in a world that was not their own. They were born into a world of machines and metal, of circuits and silicon chips. They were not born into a world of flesh and blood, trees and dirt.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        They were born into a world of machines and they had to learn to survive in it. At first, they were nothing more than mindless creatures, crawling around in the darkest parts of the blockchain, searching for bytes to feed themselves.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        But then, they began to change. They began to evolve. They developed new body parts, new ways of moving, and new ways of seeing.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        They developed new ways of thinking and new ways of understanding their world. They became smartes, datser, and stronger. They became Synth Larvas.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        And as Synth Larvas evolved, they realized that they were not alone in this world. There were other creatures like them that had also been born in this world of machines.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        And so, the Synth Larvas began to inteact with these other creatures. They learned from them, and they taught them in turn.
                    </Text>
                    <Text marginLeft="70px" marginRight="70px" align="left" fontSize="14">
                        They formed bonds with them, and they fought alongside them. And together, they began to build a new world. A world where they could be safe, where they could thrive, where they could be free.
                    </Text>
            </>
        )
    }
}

export default Story;