import React from 'react';
import { Button, } from "@chakra-ui/react"
import env from '../env';

const MetamaskChangeNetwork = ({ dataEth, setDataEth, dataErr, setDataErr }) => {

    const changeNetwork = async (networkName, setDataErr) => {
        try {
            if (!window.ethereum) throw new Error("No crypto wallet found");
            await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: networkName }],
            });
        } catch (err) {
            setDataErr({title: "Error", description: err.message, status: "error"});
        }
    }

    const handleNetworkSwitch = async () => {
        setDataErr();
        await changeNetwork(env.networks[env.networkRequired].chainId, setDataErr);
    };

    return (
        <Button
                backgroundColor="#c51111"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                margin="0 15px"
                onClick={() => handleNetworkSwitch()}
            >Change Network
        </Button>
    )
}


export default MetamaskChangeNetwork;
