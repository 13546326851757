import React from "react";
import { Flex } from "@chakra-ui/react"

import Metamask from '../Metamask/Metamask';
import env from '../env';

const NavBar = ({ dataEth, setDataEth, dataErr, setDataErr, dataNav, setDataNav }) => {

    return(
        <Flex justify="None" align="center" padding="10px" zIndex="10000" >
            <Flex 
                justify="None"
                align="right"
                width="80%" 
                padding="10px"
                marginLeft="50px"
            >
                Synth Larvas
                |&nbsp;
                Mint
                &nbsp;|&nbsp; 
                <a href={env.twitter_url} target="_blank" rel="noreferrer noopener">
                    Twitter
                </a>
                &nbsp;|&nbsp; 
                <a href={env.discord} target="_blank" rel="noreferrer noopener">
                    Discord
                </a>
                &nbsp;|&nbsp;
                <a href={env.radio} target="_blank" rel="noreferrer noopener">
                    Larvas City Radio
                </a>
                &nbsp;|&nbsp;
                <a href="#story" onClick={() => {
                            if (!dataNav) dataNav="story"; 
                            else if (dataNav==="story") dataNav="";
                            setDataNav(dataNav);
                        }}
                >
                    Story 
                </a>
            </Flex>
            
            {/* Right Side - Sections and Connect */}
            <Flex 
                justify="space-around"
                align="right"
                width="20%" 
                padding="10px"
                marginRight="50px"
            >
                <Metamask dataEth={dataEth} setDataEth={setDataEth} dataErr={dataErr} setDataErr={setDataErr} />
                
            </Flex>
            
        </Flex>
    )
}

export default NavBar;
