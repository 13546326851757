import React, { Component } from 'react';
import { Flex, Box, Text } from "@chakra-ui/react"

class Disclaimer extends Component {
    render() {
        return(
            <div>
                <Flex justify="center" align="center" height="100%" paddingBottom="20px" marginTop="220px">
                    <Box className="above" id="team">
                        <Text fontSize="14" fontWeight="600" textShadow="0 2px 2px #010101">
                            
                        </Text>
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default Disclaimer;