import { useEffect } from 'react';
import { Button, } from "@chakra-ui/react"
import { ethers } from "ethers";
import Contract from '../Contract.json'
import env from '../env';

var sha1 = require('sha1');

const MetamaskConnect = ({ dataEth, setDataEth, dataErr, setDataErr}) => {

    useEffect(() => {
        if(window.ethereum) {
          window.ethereum.on('chainChanged', () => {
            // console.log("net changed")
            checkNetwork();
            // return () => {
            //     window.ethereum.removeListener("chainChanged", networkChanged);
            //   };
          });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(window.ethereum) {
            window.ethereum.on('accountsChanged', () => {
                if (localStorage.getItem('onAccountsChangedCooldown')) return;
                localStorage.setItem('onAccountsChangedCooldown', true);
                setTimeout(() => { localStorage.setItem('onAccountsChangedCooldown', false); }, 1000)
                clickConnectButton();
                // return () => {
                //     window.ethereum.removeListener("chainChanged", networkChanged);
                //   };
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkNetwork = async() => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const networkConnected = await provider.getNetwork()
        const networkID = `0x${networkConnected?.chainId.toString(16)}`;

        if (!dataEth?.isConnected||!dataEth?.contractInfo?.loadedContract) {
            await clickConnectButton();
            return;
        }
        
        setDataEth((prevState) => ({
            ...prevState,
            networkConnected: networkID
        }));

    }

    const verifyMessage = async (message, address, signature) => {
        if (!signature) return false;
        address=address.toUpperCase();
        try {
          const signerAddr = await ethers.utils.verifyMessage(message, signature);
          if (signerAddr.toUpperCase() !== address) {
            return false;
          }
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
    }
    
    const updateContractInfo = async (contract) => {
        let tokenSymbol="",freeSupply=0,mintPrice=0, max_tx=0, maxSupply=0, totalSupply=0, enabledMint=false, pausedMint = false, loadedContract = false;
        try {
            let response = await contract.price();
            if (response)  mintPrice=ethers.utils.formatEther(response);
    
            freeSupply = ethers.utils.formatUnits(await contract.FREE_SUPPLY(), 0);
            maxSupply = ethers.utils.formatUnits(await contract.MAX_SUPPLY(), 0);
            totalSupply = ethers.utils.formatUnits(await contract.totalSupply(), 0);
            enabledMint = await contract.saleEnabled();

            tokenSymbol=await contract.symbol();
            if (Number(totalSupply)<Number(freeSupply)) {
                mintPrice=0;
                max_tx = await contract.MAX_TXN_FREE();
            } else {
                max_tx = await contract.MAX_TXN();
            }

            pausedMint = !enabledMint && totalSupply>0;
            
            loadedContract = true;
        } catch (err) {
            console.log("error: ", err)
            const _errMsg = {title: "Connection Error", description: err.message, status: "error"};
            console.log(_errMsg);
            // setDataErr(_errMsg)
        }

        return { tokenSymbol: tokenSymbol, mintPrice: mintPrice, max_tx:max_tx, freeSupply: freeSupply, maxSupply: maxSupply, totalSupply: totalSupply, enabledMint: enabledMint, pausedMint:pausedMint, loadedContract: loadedContract };
    }
    
    const updateWalletContractData = async (account) => {
        try {
            // Get Wallet Info
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const balance = ethers.utils.formatEther(await provider.getBalance(account));
            const block = await provider.getBlockNumber();
            const networkConnected = await provider.getNetwork()
            const networkID = `0x${networkConnected?.chainId.toString(16)}`;
    
            //Get Contract Info
            const signer = provider.getSigner();
            
            const contract = new ethers.Contract(
                env.mintContract,
                Contract.abi,
                signer
            );
            
            const contractInfo=await updateContractInfo(contract)
            const data = {
                networkConnected: networkID,
                isConnected: Boolean(account),
                accountEth: { account: account, balance: balance },
                contractInfo: contractInfo,
                block: block
            };

            setDataEth(data);
        } catch (err) {
            console.log("mensaje: ", err.message)
            /// if (err.message) ShowWarning(err.message)
            console.log("error: ", err)
            const _errMsg = {title: "Connection Error", description: err.message, status: "error"};
            console.log(_errMsg);
            setDataErr(_errMsg)
        }
    
        return;
    
    }
    
    const clickConnectButton = async () => {
        let account=null;
        if (!window.ethereum) {
            console.log("Install Metamask!")
            return;
        }
    
        try {
            // const accounts = await window.ethereum.request({ method: 'wallet_requestPermissions',
            //     params: [{
            //         eth_accounts: {}
            //     }]
            // }).then(() => window.ethereum.request({ method: 'eth_requestAccounts' }))
    
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts'});
            account = accounts[0];
            const provider = new ethers.providers.Web3Provider(window.ethereum)
    
            const signer = provider.getSigner();
            const message="Please sign in to SynthLarvas Authentication\r\nid:"+sha1(account);
            const variableName="synthLarvasSignature";
            let authorizedJSON=localStorage.getItem(variableName);
            let previousSign=null
            let authorizedAccts={};

            if (authorizedJSON) {
                try {
                    authorizedAccts=JSON.parse(authorizedJSON);
                }catch{
                    authorizedAccts={};
                }
                if( !(typeof authorizedAccts === "object" || typeof authorizedAccts === 'function') && (authorizedAccts !== null)) {
                    console.log("NOobject")
                    authorizedAccts={};
                }
                previousSign=authorizedAccts[account.toUpperCase()];
                // previousSign=authorizedAccts.find(e => e.account==account.toUpperCase());
                // if (previousSign) previousSign=previousSign.signedMessage;
            }
            
            const checkSignature=await verifyMessage(message, account, previousSign);

            if (!checkSignature) {
                const signature = await signer.signMessage(message);
                // authorizedAccts.push({account:account.toUpperCase(), signedMessage:signature});
                authorizedAccts[account.toUpperCase()]=signature;
                authorizedJSON=JSON.stringify(authorizedAccts,null);
                localStorage.setItem(variableName, authorizedJSON);
            }

            return updateWalletContractData(account);
    
        } catch (err) {
            console.log("mensaje: ", err.message)
            //    if (err.message) ShowWarning(err.message)
            console.log("error: ", err)
            const _errMsg = {title: "Connection Error", description: err.message, status: "error"};
            setDataErr(_errMsg)

        }
    
        return updateWalletContractData(account);
    
    }

    return (
        <Button
                backgroundColor="#252525"
                borderRadius="5px"
                boxShadow="0px 2px 2px 1px #0F0F0F"
                color="#01ff01"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                margin="0 15px"
                onClick={() => clickConnectButton(true)}
            >Connect
        </Button>
    )
}

export default MetamaskConnect;
