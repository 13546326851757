import { Text, Button } from "@chakra-ui/react"
// import { Box, Text, Flex, Button } from "@chakra-ui/react"

import env from '../env';
import Story from '../team/story';

const ProjectFooter=({ dataEth, setDataEth, dataNav, setDataNav}) => {

    const handleMint = () => {

    }

    const story = () => {
        if (dataNav==="story") {
            return (<>
                    <Story/>
                </>)
        } else {
            return (
                <a href={env.url_etherscan_address+env.mintContract} target="_blank" rel="noreferrer noopener">
                    <Text fontSize="10px">
                        Contract: {env.mintContract}
                    </Text>
                </a>
            )
        }
    }

    return(<>
            <Button
                        backgroundColor="#262626"
                        color="#01ff01"
                        borderRadius="0"
                        cursor="pointer"
                        fontFamily="inherit"
                        padding="25px"
                        paddingLeft="35px"
                        paddingRight="35px"
                        marginTop="0px"
                        onClick={handleMint}
                    >
                        MINT
            </Button>
            {story()}
        </>
        // <>
        //     <Flex justify="center" align="center" width="100%" paddingTop="30px" paddingBottom="20px" >
        //         <Button
        //             overflow="hidden"
        //             backgroundColor="green.600"
        //             borderRadius="5px"
        //             boxShadow="0px 2px 2px 1px #0F0F0F"
        //             color="white"
        //             cursor="pointer"
        //             fontFamily="inherit"
        //             fontWeight="400"
        //             padding="15px"
        //             marginTop="10px"
        //             rounded="15px"
        //             onClick={() => buttonClicked(env.url_etherscan_address+env.mintContract)}
        //         >
        //             View contract @etherscan
        //         </Button>
        //         <Box w="15%"></Box>

        //         <Button
        //             overflow="hidden"
        //             backgroundColor="green.600"
        //             borderRadius="5px"
        //             boxShadow="0px 2px 2px 1px #0F0F0F"
        //             color="white"
        //             cursor="pointer"
        //             fontFamily="inherit"
        //             padding="15px"
        //             fontWeight="400"
        //             marginTop="10px"
        //             rounded="15px"
        //             onClick={() => buttonClicked(env.raribleCollection)}
        //         >
        //             @Rarible
        //         </Button>
        //         <Box w="15%"></Box>

        //         <Button
        //             overflow="hidden"
        //             backgroundColor="green.600"
        //             borderRadius="5px"
        //             boxShadow="0px 2px 2px 1px #0F0F0F"
        //             color="white"
        //             cursor="pointer"
        //             fontFamily="inherit"
        //             padding="15px"
        //             fontWeight="400"
        //             marginTop="10px"
        //             rounded="15px"
        //             onClick={() => buttonClicked(env.looksRareCollection)}
        //         >
        //             @LooksRare
        //         </Button>
        //     </Flex>
        //     <a href={env.url_etherscan_address+env.mintContract}>
        //             <Text fontSize="10px">
        //                 Contract: {env.mintContract}
        //             </Text>
        //     </a>    
        // </>
    )
}

export default ProjectFooter;