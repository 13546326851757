import { Flex, Image } from "@chakra-ui/react"
import synth from "../assets/background/SynthLarvas.png"
                        

const ProjectDescription=({ dataEth, setDataEth}) => {
    return(
        <Flex justify="center" align="center" height="100%" paddingBottom="0px" paddingTop="0px">
            <Image src={synth} boxSize="80%" maxWidth="680px" borderRadius='full' margin="0px"/>
            {/* <Box width="650px">
                <div style={{paddingBottom: 10, opacity: '100%' }}>
                    <Text display="inline-block" textAlign="center" marginLeft="-100%" marginTop="25" marginRight="-100%" marginBottom="0" >DOSLarvas.eth</Text>
                    <Text
                        fontFamily="Londrina Solid"
                        fontSize="52px"
                        letterSpacing="-5.5%"
                        textShadow="2px 2px 14px #010101"
                        marginBottom="6"
                        marginTop="2"
                    >
                        
                    </Text>
                    <Text className="comments" >
                        Come and join our {dataEth?.contractInfo?.maxSupply ? dataEth.contractInfo.maxSupply : 2048} exclusive Larvas Synth!
                    </Text>
                    <Text className="comments" fontSize="16px">
                         All {dataEth?.contractInfo?.freeSupply ? dataEth.contractInfo.freeSupply : 2048} Larvas Synths are FREE 
                        Buy low, flip high.
                    </Text>

                    <Box className="above" id="team" paddingTop="15px">
                        <Text fontSize="14" fontWeight="600" textShadow="0 2px 2px #010101">
                            
                        </Text>
                    </Box>
                </div>
            </Box> */}
        </Flex>
    )
}

export default ProjectDescription;