import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider, theme } from "@chakra-ui/react";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
