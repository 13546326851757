import { useState } from 'react';

import './App.css';

// import Toast from './toast/toast';
import NavBar from './navbar/NavBar';
import ProjectDescription from './ProjectDescription/ProjectDescription';
import ProjectMintSuccess from './ProjectDescription/ProjectMintSuccess';
import ProjectStatus from './ProjectDescription/ProjectStatus';
import ProjectFooter from './ProjectDescription/ProjectFooter';


import Disclaimer from './disclaimer';

function App() {
  const [dataEth, setDataEth]=useState([
    { isConnected: false,
      networkRequired: null,
      accountEth: {},
      contractInfo: {} } ]);

  const [dataErr, setDataErr]=useState();
  const [dataNav, setDataNav]=useState("");

  return (
      <div className="App">
          <div className="container">
            <div className="backImage" />
            
            <div className="containerInside">
              <NavBar dataEth={dataEth} setDataEth={setDataEth} dataErr={dataErr} setDataErr={setDataErr} dataNav={dataNav} setDataNav={setDataNav}/>
              
              <ProjectMintSuccess dataEth={dataEth} setDataEth={setDataEth} />
              <div className="above">
                  <ProjectDescription dataEth={dataEth} setDataEth={setDataEth} />
                  {/* <ProjectStatus dataEth={dataEth} setDataEth={setDataEth} dataErr={dataErr} setDataErr={setDataErr} /> */}
                  <ProjectFooter dataEth={dataEth} setDataEth={setDataEth} dataNav={dataNav} setDataNav={setDataNav} />
              </div>

              

              <Disclaimer /> 
            </div>
          </div>

      </div>
      
  );


}



export default App;
